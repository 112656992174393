// User react object with username and name properties
class BggUser {
	constructor(username, name, id, tradelist, wishlist, playlist) {
		this.username = username;
		this.name = name;
		this.id = id;
		this.tradelist = [];
		this.playlist = [];
		this.wishlist = [];
	}
}

module.exports = BggUser