import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { GlobalStateContext } from '../GlobalState';

const SellerList = () => {
    const [isControlsExpanded, setIsControlsExpanded] = useState(true);
    const [isAboutExpanded, setIsAboutExpanded] = useState(false);
    const [sellerItems, setSellerItems] = useState([]);

    const { geeklistItems, usernameValue } = useContext(GlobalStateContext);

    const toggleExpanded = () => {
        setIsAboutExpanded(!isAboutExpanded);
    };

    const controlToggleExpanded = () => {
        setIsControlsExpanded(!isControlsExpanded);
    };

    // when the load button is clicked filter geeklistItems to sellers items and display them
    const handleLoadClick = () => {
        const filteredItems = geeklistItems.filter(item => item.username === usernameValue && item.sold === false);
        setSellerItems(filteredItems);
    }

    const addBBCodeToClipboard = () => {
        const bbCode = sellerItems.map(item => `[url=${item.link}]${item.item.name}[/url] $${item.price.toFixed(2)}`).join('\n');
        navigator.clipboard.writeText(bbCode);
    }

    useEffect(() => {
        if(usernameValue) {
            handleLoadClick();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usernameValue]);

    return (
        <div>
            <header className="Geeklist-header">
                <div className='Geeklist-header-bar'>
                <p>BGG Sellers Utility</p>
                <button onClick={toggleExpanded}>{isAboutExpanded ? 'Hide Details' : 'Details'}</button>
                <button onClick={controlToggleExpanded}>{isControlsExpanded ? 'Hide Controls' : 'Controls'}</button>
                </div>
                <div className="about-section">
                    {isAboutExpanded && 
                    <div className="about-details">
                        <ul>
                            <li>First you need to load a geeklist on the <Link to="/">Geeklist Utility</Link></li>
                            <li>Then type your username in the box and hit the list button</li>
                        </ul>
                        <caption>Created by Steven Branham (<a href="https://boardgamegeek.com/user/branhammer">Branhammer</a>)</caption>
                    </div>
                    }
                </div>
            </header>
            <div style={{fontSize: '.8em', float: 'left', padding: '10px'}}><Link to="/">Back to Geeklist Utility</Link></div>
            {/* Input field for entering a number and one for a username, button for submitting */}
            <div className="tableControls">
                {geeklistItems.length > 0 ? (
                    <div>
                        <div className="inputGroup">
                            <button onClick={addBBCodeToClipboard}>Copy list to clipboard</button>
                        </div>
                    </div>
                ) : (
                    <div>Please load a geeklist in the <Link to="/">Geeklist Utility</Link> first</div>
                )}
            </div>
            {sellerItems.length > 0 ? (
                <div>
                    <div style={{padding: '16px 0px'}}>
                        {sellerItems.map((item, index) => (
                            <div key={index}><a href={item.link}>{item.item.name}</a> ${item.price.toFixed(2)}</div>
                        ))}
                    </div>
                </div>
            ): (
                <div>Please load a username</div>
            )}
        </div>
    );
}

export default SellerList;