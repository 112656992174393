export const findPrice = (inputString) => {
    const pattern = /\$(\d+(\.\d+)?)/g;
    let matches = inputString.match(pattern)?.map(match => match.replace(/\$/g, ''));

    // if no match try to match on any number
    if (!matches) {
        const numPattern = /\d+(\.\d+)?/g;
        matches = inputString.match(numPattern)?.map(match => match.replace(/\$/g, ''));
    }

    const smallestValue = matches ? Math.min(...matches) : null;
    return matches ? smallestValue : "?";
};

export const findPriceType = (inputString) => {
    // Look for the presence of BIN, FP, SB followed by a number on the same line, optionally matching a $

    const sbPattern = /(SB).+[$]{0,1}(\d+)/i;       // Starting bid
    const srPattern = /(SR).+[$]{0,1}(\d+)/i;       // Soft reserve
    const fpPattern = /(FP).+[$]{0,1}(\d+)/i;       // Fixed price
    const binPattern = /(BIN).+[$]{0,1}(\d+)/i;     // Buy it now

    switch (true) {
        case sbPattern.test(inputString):
            return "SB";
        case srPattern.test(inputString):
            return "SR";
        case fpPattern.test(inputString):
            return "FP";
        case binPattern.test(inputString):
            return "BIN";
        default:
            return "N/A";
    }
}

export const findRemove = (inputString) => {
    const pattern = /\bremove\b/i;
    return pattern.test(inputString);
}

export const findSold = (inputString) => {
    const pattern = /\bsold\b|\btraded\b(?!\s+notsold\b)/i;
    return pattern.test(inputString);
};