const LoadingInline = ({message, progress, type}) => {
    // round progress to two decimal places
    progress = progress.toFixed(2);

    return (
        <div className="loading-inline-container">
            <div className="loading-inline-content">
                {/* animated progress bar */}
                <caption>{message}...</caption>
                {type === 'progress' && (
                    <div className="progress-bar-container">
                        <div className="progress-bar">
                            <div className="progress" style={{width: `${progress}%`}}></div>                    
                        </div>
                        <caption>{progress}%</caption>
                    </div>
                )}
                {type === 'spinner' && (
                    <div className="loader"></div>
                )}
            </div>
        </div>
    );
}

export default LoadingInline;

