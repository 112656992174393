import React, { useState, useContext } from 'react';
import { GlobalStateContext } from '../GlobalState';

const BggPlay = () => {
    const [isControlsExpanded, setIsControlsExpanded] = useState(true);
    const [isAboutExpanded, setIsAboutExpanded] = useState(false);

    const { bggUsers } = useContext(GlobalStateContext);

    const toggleExpanded = () => {
        setIsAboutExpanded(!isAboutExpanded);
    };

    const controlToggleExpanded = () => {
        setIsControlsExpanded(!isControlsExpanded);
    };

    return (
        <div>
            <header className="Geeklist-header">
                <div className='Geeklist-header-bar'>
                <p>BGG Want to Play Utility</p>
                <button onClick={toggleExpanded}>{isAboutExpanded ? 'Hide Details' : 'Details'}</button>
                <button onClick={controlToggleExpanded}>{isControlsExpanded ? 'Hide Controls' : 'Controls'}</button>
                </div>
                <div className="about-section">
                    {isAboutExpanded && 
                    <div className="about-details">
                        <ul>
                            <li>Enter the geeklist id you would like to view. Hit the load button, it takes about a minute to load all the items for a big list.</li>
                            <li>Clicking the column header will sort by that column.</li>
                            <li>The icon button at the right side of the column will allow you to filter.</li>
                            <li>If you click the button in the 'Details' column it will load the listing description and comments so you can quickly see if it's sold or what the current bid is.</li>
                            <li>The sold column is not nearly accurate because it depends on text in the description, so use the 'Details' button to check listings before getting too excited about what you see in the table. I'm working to make this more robust.</li>
                        </ul>
                        <caption>Created by Steven Branham (<a href="https://boardgamegeek.com/user/branhammer">Branhammer</a>)</caption>
                    </div>
                    }
                </div>
            </header>
            {/* loop through bggUsers and display them */}
            {bggUsers.map((bggUser, index) => (
                <div key={index}>{bggUser.username}</div>
            ))}
        </div>
    );
}

export default BggPlay;