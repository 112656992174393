import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { useState, useEffect, useRef } from 'react';
import { GlobalStateProvider } from './GlobalState';
import { BggList, BggPlay, SellerList } from './components';
// import { CgMenuGridR } from "react-icons/cg";
import './App.css';

function App() {
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const menuRef = useRef(null);

  // const toggleMenu = () => {
  //   setIsMenuOpen(!isMenuOpen);
  // }

  // const handleClickOutside = (event) => {
  //   if (menuRef.current && !menuRef.current.contains(event.target)) {
  //     setIsMenuOpen(false);
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener('click', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, []);

  return (
    <GlobalStateProvider>
      <Router>
        <div className="App">
          <div id="modal-root"></div>
          {/* <div className="icon-menu" ref={menuRef}>
            <button className="icon-menu-button" onClick={toggleMenu}>
              <CgMenuGridR />
            </button>
            <div className={`icon-menu-panel ${isMenuOpen ? 'open' : ''}`}>
              <Link to="/" className="icon-menu-item">Geeklist Utility</Link>
              <Link to="/seller-list" className="icon-menu-item">Sellers Utility</Link>
            </div>
          </div> */}
          {/* <div id="nav-root">
            <Link to="/">List</Link>
            <Link to="/play">Play</Link>
          </div> */}
          <Routes>
            <Route exact path="/" element={<BggList />} />
            <Route path="/play" element={<BggPlay />} />
            <Route path="/seller-list" element={<SellerList />} />
          </Routes>
        </div>
      </Router>
    </GlobalStateProvider>
  );
}

export default App;