import React, { useState, useEffect, useCallback } from 'react';
import Modal from './modal.js';
import { BBCodeParser, useBggUsers } from '../helpers';
import { Comment } from '../objects';
import { fetchData } from '../services';

const BggGameModal = ({isModalOpen, setIsModalOpen, modalData}) => {

    const { findBggUser } = useBggUsers();

    const [isModalLoading, setIsModalLoading] = useState(false);
    const [modalTitle, setmodalTitle] = useState("Placeholder modalTitle");
    const [modalPriceHistoryComment, setModalPriceHistoryComment] = useState("");
    const [modalDescription, setModalDescription] = useState("loading...");
    const [modalComments, setModalComments] = useState([]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    function calculateZScore(numbers) {
        const mean = numbers.reduce((acc, val) => acc + val, 0) / numbers.length;
        const variance = numbers.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / numbers.length;
        const standardDeviation = Math.sqrt(variance);
        const zScores = numbers.map(num => (num - mean) / standardDeviation);
        return zScores;
      }

    const readyModalData = useCallback(async () => {
        setIsModalLoading(true);

        const body = modalData.body;
        const id = modalData.id;
        try {
            const commentsData = await fetchData("/api/listitems/".concat(id, "/comments"));
            if(commentsData && commentsData.comments){
                let commentsList = [];
                for (let index = 0; index < commentsData.comments.length; index++) {
                    let comment = commentsData.comments[index];
                    let author = await findBggUser(comment.author);
                    commentsList.push(new Comment(author.username, comment.body, comment.postdate));
                }
                setModalComments(commentsList);
            } else {
                setModalComments([])
            }
        } catch (error) {
            console.error("Error fetching comments:", error);
        }

        try {
            const priceHistoryData = await fetchData("/api/market/products/pricehistory?ajax=1&condition=any&currency=USD&objectid=".concat(modalData.item.id, "&objecttype=thing&pageid=1"));
            if(priceHistoryData){
                let pricesSum = 0;
                const zThreshold = 2;
                const priceArray = priceHistoryData.items.map(item => parseFloat(item.price));
                const zScores = calculateZScore(priceArray);
                const outliers = priceArray.filter((item, index) => {
                    return zScores[index] > zThreshold || zScores[index] < -zThreshold
                });
                const core = priceArray.filter((item, index) => zScores[index] > -zThreshold && zScores[index] < zThreshold);
                

                // calculate average ignoring outliers
                for (let index = 0; index < core.length; index++) {
                    let price = core[index];
                    pricesSum = pricesSum + parseInt(price);
                }

                let comment = `BGG marketplace average price for the last ${core.length} sales was $${(pricesSum/core.length).toFixed(2)}.`;
                if(outliers.length > 0){
                    comment = comment.concat(` ${outliers.length} ${outliers.length > 1 ? "were" : "was"} ignored as ${outliers.length > 1 ? "outliers" : "an outlier"}: $${outliers.join(', $')}`)
                }
                
                setModalPriceHistoryComment(comment);
            } else {
                setModalPriceHistoryComment("No price history found");
            }
        } catch (error) {
            console.error("Error fetching price history:", error);
        }

        setModalDescription(body);
        setmodalTitle(modalData.item.name);
        setIsModalLoading(false);
    }, [modalData, findBggUser]);

    useEffect(() => {
        if(isModalOpen){
            readyModalData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen]);

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            {isModalLoading ? <div>Loading...</div> : 
            <div>
                <h2>{modalTitle}</h2>

                <h3 style={{marginTop:'0px'}}>Description</h3>
                <div style={{textAlign:'center', paddingBottom:'10px'}}>{modalPriceHistoryComment}</div>
                <div style={{textAlign:'left', padding:'10px', border:'1px black solid'}}>
                    <BBCodeParser bbcode={modalDescription} />
                </div>
                
                <h3>Comments</h3>
                {modalComments.length > 0 ? <table style={{width:'100%'}}>
                    <colgroup>
                        <col style={{width: '20%'}}/>
                        <col style={{width: '20%'}}/>
                        <col style={{width: '60%'}}/>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>Time</th>
                            <th>Author</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {modalComments.map(comment => (
                            <tr>
                                <td>
                                    <div>{new Date(comment.time).toLocaleDateString()}</div>
                                    <div>{new Date(comment.time).toLocaleTimeString()}</div>
                                </td>
                                <td>{comment.author}</td>
                                <td style={{textAlign:'left'}}>{comment.body}</td>
                            </tr>
                        ))}
                    </tbody>
                </table> : 
                <p>There are no comments on this list item</p>}
            </div>}
        </Modal>
    )
}

export default BggGameModal;