import React, { createContext, useState } from 'react';

export const GlobalStateContext = createContext();

export const GlobalStateProvider = ({ children }) => {
  const [bggUsers, setBggUsers] = useState([]);
  const [geeklistItems, setGeeklistItems] = useState([]);
  const [usernameValue, setUsernameValue] = useState('');

  return (
    <GlobalStateContext.Provider value={{ bggUsers, setBggUsers, geeklistItems, setGeeklistItems, usernameValue, setUsernameValue }}>
      {children}
    </GlobalStateContext.Provider>
  );
};