import { GlobalStateContext } from '../GlobalState';
import { useContext } from 'react';
import { fetchData } from '../services';
import { BggUser } from '../objects';


const useBggUsers = () => {
    const { bggUsers, setBggUsers } = useContext(GlobalStateContext);

    const findBggUser = async (id) => {
        let users = bggUsers;
        if (!users.some(user => user.id === id)) {
            const userData = await fetchData(`/api/users/${id}`);
            let user = new BggUser(userData.username, userData.name, id, [], [], []);
            users.push(user);
            return user;
        }
        setBggUsers(users);
        return users.find(user => user.id === id);
    }

    return {
        findBggUser
    };
};

export default useBggUsers;