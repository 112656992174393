import React from 'react';

class BBCodeParser extends React.Component {
  parseBBCode(text) {
    // Replace newlines with <br>
    text = text.replace(/\n/g, '<br>');

    // Replace [b]...[/b] with <strong>...</strong>
    text = text.replace(/\[B\](.*?)\[\/B\]/gi, '<strong>$1</strong>');
    
    // Replace [i]...[/i] with <em>...</em>
    text = text.replace(/\[I\](.*?)\[\/I\]/gi, '<em>$1</em>');
    
    // Replace [u]...[/u] with <u>...</u>
    text = text.replace(/\[U\](.*?)\[\/U\]/gi, '<u>$1</u>');

    // Replace [url=...]...[/url] with <a href="...">...</a>
    text = text.replace(/\[url=(.*?)\](.*?)\[\/url\]/gi, '<a href="$1">$2</a>');

    // Replace [size=...]...[/size] with <span style="font-size: ...px">...</span>
    text = text.replace(/\[size=(.*?)\](.*?)\[\/size\]/gi, '<span style="font-size: $1px">$2</span>');

    return text;
  }

  render() {
    const { bbcode } = this.props;
    const html = this.parseBBCode(bbcode);
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }
}

export default BBCodeParser;
