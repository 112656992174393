function parseXML(xml) {
    return new Promise((resolve, reject) => {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'application/xml');
    
        if (xmlDoc.getElementsByTagName('parsererror').length > 0) {
          reject(new Error('Invalid XML'));
        } else {
          resolve(xmlDoc);
        }
    });
}

export default parseXML;