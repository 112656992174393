import React, { useState, useRef } from 'react';
import { fetchData, fetchResponse } from '../services';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { parseXml } from '../helpers';
import LoadingInline from './loading-inline.js';
import { findPrice, findPriceType, findRemove, findSold, useBggUsers } from '../helpers';
import BggGameModal from './bgg-game-modal.js';
import { GlobalStateContext } from '../GlobalState';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

const BGG_BASE_URL = "https://boardgamegeek.com";


function GameLinkRenderer(props) {
    return (
      <a style={{ float: 'left' }} href={BGG_BASE_URL.concat(props.data.item.href)}>{props.value}</a>
    );
}

function LinkRenderer(props) {
    return (
      <a href={props.value}>{props.value}</a>
    );
}

function LeftAlignedCellRenderer(props) {
    return <span style={{ float: 'left' }}>{props.value}</span>;
}

function BooleanCellRenderer(props) {
    return <span style={{ float: 'left' }}>{props.value ? "True" : "False"}</span>;
}

const BggList = () => {
    const base_url = "/api/listitems";
    const xml_base_url = "/xmlapi2";

    const { geeklistItems, setGeeklistItems, usernameValue, setUsernameValue } = useContext(GlobalStateContext);

    const [wishlistData, setWishlistData] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalGameData, setModalGameData] = useState({});

    const [listIdValue, setlistIdValue] = useState('331341');
    const [isLoading, setIsLoading] = useState(false);
    const [progressValues, setProgressValues] = useState({message: "Loading", progress: 0, type: 'progress'});
    const [rowCount, setRowCount] = useState(0);
    const [isControlsExpanded, setIsControlsExpanded] = useState(true);
    const [isAboutExpanded, setIsAboutExpanded] = useState(false);

    const { findBggUser } = useBggUsers();

    const toggleExpanded = () => {
        setIsAboutExpanded(!isAboutExpanded);
    };

    const controlToggleExpanded = () => {
        setIsControlsExpanded(!isControlsExpanded);
    };

    const gridRef = useRef();

    const handleListIdValue = (event) => {
      setlistIdValue(event.target.value);
    };

    const handleUsernameValue = (event) => {
      setUsernameValue(event.target.value);
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    // Define ButtonRenderer within BggList
    function ButtonRenderer(props) {
        const handleClick = () => {
            setModalGameData(props.data);
            openModal();
        };

        return (
            <button onClick={handleClick}>
                Check Listing
            </button>
        );
    }

    // Defint DateRenderer within BggList
    function DateRenderer(props) {
        let date = new Date(props.value);
        // Format date to DD/MM/YYYY
        const dateString = date.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
        
        // // Format time as HH:MM AM/PM
        // const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
        // //display date on one line and time on another
        // return <span>{dateString} @ {time}</span>;

        return <span>{dateString}</span>;
    }

    function TimeRenderer(props) {
        let date = new Date(props.value);
        // Format time as HH:MM AM/PM
        const time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' });
        return <span>{time}</span>;
    }

    function PriceRenderer(props) {
        if(props.value === "?" || props.value === "N/A" || props.value === "" || props.value === 0){ 
            return <span>{props.value}</span>;
        } else {
            return <span>${props.value}</span>;
        }
    }

    const isExternalFilterPresent = () => {
        return (wishlistData.length > 0);
    }

    const doesExternalFilterPass = (node) => {
        return wishlistData.find((id) => {
            return (id === node.data.item.id) ? true : false;
        })
    }

    const timeComparator = (time1, time2) => {
        // Convert the time strings to Date objects for comparison
        const date1 = new Date(time1);
        const date2 = new Date(time2);
        
        // Compare the Date objects
        if (date1 < date2) {
            return -1;
        } else if (date1 > date2) {
            return 1;
        } else {
            return 0;
        }
    };

    const dateComparator = (date1, date2) => {
        // Convert the time strings to Date objects for comparison
        const dateObj1 = new Date(date1).setHours(0, 0, 0, 0);
        const dateObj2 = new Date(date2).setHours(0, 0, 0, 0);
        
        // Compare the Date objects
        if (dateObj1 < dateObj2) {
            return -1;
        } else {
            return 1;
        }
    }

    const priceComparator = (price1, price2) => {
        if(price1 === '?') return 1;
        if(price2 === '?') return -1;
        return price1 > price2 ? 1 : -1;
    }

    const columnDefs = [
        { headerName: 'List Date', field: 'postdate', filter: 'agDateColumnFilter', comparator: dateComparator, cellRenderer: DateRenderer, width: 120 },
        { headerName: 'List Time', field: 'postdate', filter: true, comparator: timeComparator, cellRenderer: TimeRenderer },
        { headerName: 'Avg. Rating', field: 'stats.average', filter: 'agNumberColumnFilter', cellRenderer: LeftAlignedCellRenderer, width: 80 },
        { headerName: 'Name', field: 'item.name', filter: true, cellRenderer: GameLinkRenderer, width: 200 },
        { headerName: 'Price', field: 'price', filter: 'agNumberColumnFilter', comparator: priceComparator, cellRenderer: PriceRenderer},
        { headerName: 'Type', field: 'priceType', filter: true },
        { headerName: 'Seller', field: 'username', filter: true, cellRenderer: LeftAlignedCellRenderer },
        { headerName: 'Details', 
            field:'body',
            cellRenderer: ButtonRenderer, 
            cellRendererParams: {
                openModal: openModal
            }
        },
        { headerName: 'Link', field: 'link', cellRenderer: LinkRenderer, width: 100 },
        { headerName: 'Sold', field: 'sold', filter: true, cellRenderer: BooleanCellRenderer, width: 100 },
        { headerName: 'Expansion', field: 'expansion', filter: true, cellRenderer: BooleanCellRenderer, width: 100 }
    ];

    const autoSizeStrategy = {
        type: 'fitCellContents',
        skipHeader: true
    };

    const updateRowCount = () => {
        setRowCount(gridRef.current?.api.getDisplayedRowCount()); 
    }

    const fetchDataAndProcess = async () => {
        let loadingMessage = "Loading geeklist items";
        setProgressValues({message: loadingMessage, progress: 0, type: "progress"});
        setIsLoading(true);

        let total = 0;
        let page = 1;
        let lastpage = 10;
        const newData = [];

        while (true) {
            const url = `${base_url}?listid=${listIdValue}&page=${page}`;
            const result = await fetchData(url);

            if (!result) break; // Break out of the loop if there's an error or no more data

            if (total === 0) {
                total = result.pagination.total;
                lastpage = Math.ceil(total / 25);
            }

            let progress = (page / lastpage) * 100;
            setProgressValues({message: loadingMessage, progress: progress, type: "progress"});

            newData.push(...result.data);

            if (page === lastpage) break;
            page++;
        }

        const filteredData = newData.filter(item => !findRemove(item.body));

        // Loop filteredData getting user data for all items
        const filteredDataWithUsername = [];
        loadingMessage = "Finding usernames";
        setProgressValues({message: loadingMessage, progress: 0, type: "progress"});
        for (let index = 0; index < filteredData.length; index++) {
            const item = filteredData[index];
            const user = await findBggUser(item.author);
            if (user) {
                filteredDataWithUsername.push({ ...item, username: user.username });
            }
            let progress = (index / filteredData.length) * 100;
            setProgressValues({message: loadingMessage, progress: progress, type: "progress"});
        }

        const processedData = filteredDataWithUsername.map(item => ({
            ...item,
            postdate: new Date(item.postdate),
            price: findPrice(item.body),
            priceType: findPriceType(item.body),
            sold: (item.salesitems && item.saleitems.length > 0) || findSold(item.body),
            expansion: /\bboardgameexpansion\b/i.test(item.item.href),
            link: BGG_BASE_URL.concat(item.href),
        }));

        setGeeklistItems(processedData);
        setIsLoading(false);
        // fetchAdditionalXmlData();
    };

    // const fetchAdditionalXmlData = async () => {
    //     setIsLoading(true);
    //     let loadingMessage = "Loading xml sale data, attempt 1";
    //     setProgressValues({message: loadingMessage, progress: 0, type: "spinner"});
    //     let iterations = 1;
    //     let result = "";
    //     while(true){
    //         result = await fetchResponse(`${xml_base_url}/geeklist/${listIdValue}`)
    //         if (result.status !== 200) {
    //             if (iterations > 15) {
    //                 console.error("Exceeded attempt limit");
    //                 break;
    //             }
                
    //             // wait 5 seconds and try again
    //             await new Promise(resolve => setTimeout(resolve, 5000));

    //             iterations++;
    //             loadingMessage = `Loading xml sale data, attempt ` + iterations;
    //             setProgressValues({message: loadingMessage, progress: 0, type: "spinner"});
    //             continue;
    //         } else {
    //             break;
    //         }
    //     }

    //     if (result.status !== 200) {
    //         console.error("Failed to load xml sale data");
    //         setIsLoading(false);
    //         return;
    //     }

    //     setProgressValues({message: "Parsing sale data", progress: 0, type: "spinner"});
    //     let xmlDoc = await parseXml(result.data);
    //     const xmlItems = xmlDoc.getElementsByTagName('item');
    //     let updatedSaleData = data;
    //     for(let i = 0; i < xmlItems.length; i++) {
    //         const item = xmlItems[i];
    //         const id = item.getAttribute('id');
    //         if(item.getAttribute('sold') === '1') {
    //             let index = updatedSaleData.findIndex(item => {
    //                 return item.id.localeCompare(id) === 0;
    //             });
                    
    //             if(index === -1) {
    //                 console.warn('Could not find data item with id', id);
    //                 continue;
    //             }

    //             updatedSaleData[index].sold = true;
    //         }
    //     }
    //     if(updatedSaleData.length > 0) {
    //         setData(updatedSaleData);
    //     }
    //     setIsLoading(false);
    // }

    const fetchWishlistDataAndProcess = async () => {
        let loadingMessage = "Wishlist attempt 1";
        setProgressValues({message: loadingMessage, progress: 0, type: "spinner"});
        setIsLoading(true);

        let iterations = 1;
        let result = "";
        while(true){
            result = await fetchResponse(`${xml_base_url}/collection?username=${usernameValue}&wishlist=1`)
            if (result.status !== 200) {
                // wait 5 seconds and try again
                await new Promise(resolve => setTimeout(resolve, 5000));
                
                if (iterations > 4) {
                    console.error("Error fetching data from ", xml_base_url);
                    break;
                }
                
                iterations++;
                loadingMessage = `Wishlist attempt ` + iterations;
                setProgressValues({message: loadingMessage, progress: (iterations/5)*100});
                continue;
            } else {
                break;
            }
        }
        
        let xmlDoc = await parseXml(result.data);
        const wishlistItems = xmlDoc.getElementsByTagName('item');
        let wishlistIds = [];

        for(let index = 0; index < wishlistItems.length; index++) {
            wishlistIds.push(wishlistItems[index].getAttribute('objectid'));
        }

        setWishlistData(wishlistIds);
        setIsLoading(false);
    }

    const clearWishlist = () => {
        setWishlistData([]);
    }


    return (
        <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
            <header className="Geeklist-header">
                <div className='Geeklist-header-bar'>
                <p>BGG Geeklist Utility</p>
                <button onClick={toggleExpanded}>{isAboutExpanded ? 'Hide Details' : 'Details'}</button>
                <button onClick={controlToggleExpanded}>{isControlsExpanded ? 'Hide Controls' : 'Controls'}</button>
                </div>
                <div className="about-section">
                    {isAboutExpanded && 
                    <div className="about-details">
                        <ul>
                            <li>Enter the geeklist id you would like to view. Hit the load button, it takes about a minute to load all the items for a big list.</li>
                            <li>Clicking the column header will sort by that column.</li>
                            <li>The icon button at the right side of the column will allow you to filter.</li>
                            <li>If you click the button in the 'Details' column it will load the listing description and comments so you can quickly see if it's sold or what the current bid is.</li>
                            <li>Details also houses an average price for the item based on recent sales on BGG</li>
                        </ul>
                        <caption>Created by Steven Branham (<a href="https://boardgamegeek.com/user/branhammer">Branhammer</a>)</caption>
                    </div>
                    }
                </div>
            </header>
            <BggGameModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modalData={modalGameData}/>
            {isControlsExpanded && (
                <div className='tableControls'>
                    <div className='listIdInput inputGroup'>
                        <label htmlFor="listIdInput">Geeklist Id</label>
                        <input id='listIdInput' type="text" value={listIdValue} onChange={handleListIdValue} />
                        <button onClick={fetchDataAndProcess}>Load</button>
                    </div>

                    <span class="bar">|</span>
                    
                    <div className='usernameInput inputGroup'>
                        {/* <label htmlFor="usernameInput">Filter by wishlist</label> */}
                        <input id='usernameInput' type="text" placeholder="BGG Username" value={usernameValue} onChange={handleUsernameValue} />
                        <button onClick={fetchWishlistDataAndProcess} disabled={geeklistItems.length === 0}>Filter by Wishlist</button>
                        <button onClick={clearWishlist} disabled={wishlistData.length === 0}>Clear</button>
                        {/* vertical bar */}
                        <span class="bar">|</span>
                        <Link to="/seller-list"><button disabled={geeklistItems.length === 0 || usernameValue.length === 0}>Sellers List</button></Link>
                    </div>

                    {/* <div className='saleDataInput inputGroup'>
                        <button onClick={fetchAdditionalXmlData} disabled={data.length === 0}>Request Sale Data</button>
                    </div> */}
                </div>
            )}
            <div className='tableInfo'>
                <caption>Number of Rows: {rowCount}</caption>
                {isLoading && (
                    <LoadingInline message={progressValues.message} progress={progressValues.progress} type={progressValues.type} />
                )}
            </div>
            
             <div className="ag-theme-alpine" style={{ display: 'flex', flex: '1 1' }}>
                <div style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columnDefs}
                        autoSizeStrategy={autoSizeStrategy}
                        rowData={geeklistItems}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                        onGridReady={updateRowCount}
                        onModelUpdated={updateRowCount}>
                    </AgGridReact>
                </div>
            </div>
        </div>
    );
};

export default BggList;
